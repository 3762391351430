
@tailwind base;
@tailwind components;
@tailwind utilities;


.uplodebtn { 
    @apply w-full rounded flex justify-center items-center bg-[#3498db] text-white text-[14px] font-bold p-[5px] border-none cursor-pointer transition-all duration-300 hover:bg-[#2980b9] active:translate-y-[1px] 
 }
.draganddrop {
    @apply flex justify-center w-full h-full px-4 py-10 border-2 border-dashed border-[#3498db] rounded-[5px] text-[14px] font-bold text-[#3498db] cursor-pointer relative
}
.filechat {
    @apply px-[10px] flex items-center py-[5px] mb-[14px] rounded-[5px] bg-[#223045]  transition-all duration-300  hover:shadow-lg cursor-pointer hover:-translate-y-[2px] 
}